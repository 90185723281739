/**
 * Typo
 */

.entry {
	
	a {
		text-decoration: underline;
	}
	
	p {
		margin-top: .5rem;
		margin-bottom: 1rem;
	}
	
}

.cke_editable_themed {
	padding: .5rem;
	
	p {
		margin-bottom: 1rem;
	}
	
	a {
		text-decoration: underline;
	}
}

.cke_widget_drupalmedia {
	
	.l-embed {
		width: 100% !important;
	}
}

@media only screen and (min-width: $bp-l) {
	
}
