/*
 * ==================== @ Generic layout All interfaces
 */


/* Table layout
----------------------------------*/

.table-layout {
	max-width: 100%;
	width: 100%;
		
	thead th {
		color: $grey;
		font-size: em(15);
		font-weight: $fw-semibold;
		text-align: left;
	}
}

/* Iframe layout (popup)
----------------------------------*/
.iframe-layout {
	padding: 1rem;
}

/* Table in section Listing
----------------------------------*/
.section-listing {
	
	.table-layout {
		
		thead {
			background-color: $grey-caption;
			
			th {
				padding: 1rem .5rem;
			}
		}
		
		tbody {
			
			tr {
				background-color: $white;
				border-top: .5rem solid $grey-lite;
				border-bottom: .5rem solid $grey-lite;
				
				&:hover {
					background-color: rgba($blue, .05);
				}
			}
			
			td {
				font-size: em(12);
				font-weight: $fw-medium;
				padding: .5rem;
			}		
		}
		
		&.theme-webshop {
			
			.amount {
				font-weight: $fw-bold;
				text-transform: uppercase;
			}
			
			.header-order {
				
				td {
					background-color: $grey-caption;
				}
			}
		}
		
		&.theme-reporting {
				
			tbody td:not(.first-col) {
				text-align: right;
				padding-right: 1.2rem;
			}
			
			&.m--monthly	 {
				
				thead {					
					th {
						font-size: em(13);
						padding: 1rem .5rem;
						text-align: center;
					}
				}
			}
			
		}	
	}
}

/* Main section
----------------------------------*/

.section {
	
	.fielset-layout {
		background-color: $white;
		box-shadow: 0 5px 36px rgba(#9EA1A6, .2);
		padding: 1rem 1rem;
		margin-bottom: 2rem;
		
		fieldset {
			max-width: 840px;
			
			&.larger {
				max-width: 1024px;
			}
		}
		
		legend {
			color: $blue;
			font-size: em(20);
			font-weight: $fw-bold;
			font-family: $alt-font-stack;	
			margin-bottom: 1rem;	
		}
		
		.legend-as-subnav {
			span {
				padding: 0 .5rem;
			}
			a.active, a:hover {
				text-decoration: underline;
			}
		}
		
		&.content-filters {
			padding: 1rem 6rem;
		}
		
		.tip {
			display: block;
			font-size: em(15);
			margin-bottom: .5rem;
		}
	}
		
	.filters {
		display: flex;
		
		label {		
			color: $grey;
			font-size: em(15);
			font-weight: $fw-semibold;
			
			&.m--center {
				line-height: 2;
			}
		}
		
		li {
			padding: .5rem;
		}
	}
	
	.bloc-layout {
		padding-top: 1rem;
		padding-bottom: 1rem;
		
		&.m--right {
			text-align: right;
		}
	
		&.status-legends {
			
			li {
				display: inline-block;
				font-size: em(13);
				margin-right: 1rem;
				
				&:first-child {
					color: $blue;
					font-size: em(14);
					font-weight: $fw-bold;
				}
			}
		}	
		
		&.search-product {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			
			li {
				flex: 0 0 auto;
				margin-left: 1rem;
				
				&:first-child {
					margin-left: 0;
				}
			}
			
		}
	}
	
	.content-actions {
		padding-bottom: 1rem;
		
		&.m--right {
			text-align: right;
			padding-right: 1rem;
		}
	}
	
	/* Medias home
	----------------*/	
	&.content-mediacentre {		
		max-width: 80em;
		margin: 0 auto;
		padding-top: 0;
		
		.sv-u-m-1-2 {
			position: relative;
			padding: 1em;
			
			img {
				border-radius: 4px;
				display: block;
			}
		}
		
		.grid-col__bloc {
			position: absolute;
			left: 50%;
			z-index: 2;
			
			p {
				color: $white;	
				font-size: em(20);
				font-weight: $fw-bold;
				text-transform: uppercase;			
				text-align: center;
			}
			
			&:nth-child(even) {
				top: 10%;
				transform: translate(-50%,-10%);
			}
			
			&:nth-child(odd) {
				bottom: 10%;
				transform: translate(-50%,-10%);				
			}
		}		
	}
		
	/* Claims
	----------------*/	
	.claim-edit__header {
		position: sticky;
    top: -30px;
		z-index: 99;
		
		.claim-dealer-edit & {
			top: 100px;
		}
		
		.fielset-layout {
			padding-top: 1rem;
			
			.form-field {
				padding: .1rem .5rem;
			}
		}
	}
	
	&.claim-dealer-edit .claim-edit__header {
		top: 104px;
	}
	
	.m--fixed-bottom {
		background-color: $grey-lite;
		box-shadow: 0 -3px 16px rgba(#9EA1A6, .15);
		position: fixed;
		left: 0;
		right: 0;
		bottom: -10px;
		text-align: center;
		width: 100%;
		z-index: 100;
	}
		
	.claim-tabs {
		display: flex;
		margin-top: 2rem;
		margin-bottom: -2rem;
		
		li {
			background-color: #e7e7e7;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			margin-right: .5rem;
			position: relative;
			transition: background-color 150ms, color 150ms;
			
			span {
				width: 32px;
				height: 16px;
				position: absolute;
				overflow: hidden;
				left: 20%;
				bottom: -16px;	
				opacity: 0;
				transition: opacity 150ms;
			
				&::after {
					content: "";
					background-color: $blue;
					position: absolute;
					width: 16px;
					height: 16px;
					transform: rotate(135deg);
					top: -8px;
					left: 8px;
				}
			}
			
			a {
				display: block;
				padding: .5rem 1rem;
			}
			
			&.active, &:hover {
				background-color: $blue;
				color: $white;
				
				span {
					opacity: 1;
				}			
			}
		}
	}
	
	.medias-separator {
		background-color: $blue;
		height: 1px;	
		margin: 1rem 0;		
	}
	
	.table-layout {
		
		/* fix button action pos */			
		td.cell-button--margin {
			padding-top: 10px;
		}
			
		.table-layout-bottom td {
			padding-top: 1rem;
			
			.label-outlink {
				display: inline-block;
				font-size: em(14);
				margin-left: 1rem;
				vertical-align: -2px;
			}
		}
		
		/* Messages
		----------------*/	
		&.claim-messaging {
			
			.message-head th {
				font-size: em(14);
				font-weight: normal;
				text-align: left;
				padding-bottom: .3rem;
			}
			
			.message-body td {
				font-size: em(15);
				padding-bottom: 1rem;
				
				div {
					background-color: $grey-field;
					font-size: em(15);
					padding: .5rem;
				}
			}
		}
		
		/* Warranty process
		----------------*/
		&.infos-warranty-code,
		&.warranty-process-repairs,
		&.warranty-process-costs {
			thead th {
				padding-left: .5rem;
			}
		}
		
		&.infos-warranty-code {		
			
			td.cell-code--size {
				width: 10%;
			}	
		}
	
		/* Infocentre
		----------------*/
		&.payment-process {
			
			.infos-payment-alert {
				
				li {
					display: inline-block;
				}
				
				.message {
					display: none;
					color: var(--red);
					font-size: em(14);
					font-style: italic;
					padding-left: 1rem;
					
					&.alert {
						display: block;
					}
				}
			}
		}
	}
	
		/* Messages - Attachements
		-------------------------*/	
	
	&.messaging {
		
		.message_attachments {
			font-size: em(13);
			padding-bottom: 1rem;
			
			a {
				text-decoration: underline;
			}
		}
	}
	
	.warranty-process-header {
		max-width: 75%;
	}
	
	.warranty-process-resume {
		border-left: 1px solid $grey-field;
		margin-left: 1rem;
		padding-left: .5rem;
		
		.form-field {
			padding: .5rem 0;
		}
	}
}

/* Cards in grid layout
----------------------------------*/ 
.content-grid {
	
	.grid-card {		
		box-shadow: 0 5px 16px rgba(#9EA1A6, .15);
		margin: 1rem;
		padding: 1rem;
		
		li {
			font-size: em(14);
			margin-bottom: 1rem;
		}
		
		.document {
			
			.svg-icon {
				font-size: em(32);
			}
		}
		
		.caption {
			height: 36px;
			overflow: hidden;
		}
		
		.download {
			color: $blue;
			
			a {
				font-style: italic;
			}
		}		
	}	
}

/* Statuses color
----------------------------------*/
.valid {
	color: var(--green);
}

.unvalid {
	color: var(--red);
}

.alert {
	color: var(--orange);
}

.info {
	color: var(--liteseagreen);
}

i.status {
	display: block;
	
	&::before {
		content: '';
		display: block;
		height: 10px;
		width: 10px;
		border-radius:: 50%;
		cursor: help;		
	}
	&.s-2::before {
		background-color: var(--grey);
	}
	&.s-1::before {
		background-color: var(--blue);
	}
	&.s0::before {
		background-color: var(--orange);
	}
	&.s1::before {
		background-color: var(--green);
	}
	&.s2::before {
		background-color: var(--red);
	}
}

.theme-webshop,
.status-legends {
	
	i.status {
		display: inline-block;
		margin-right: .35rem;
		
		&.s3::before {
			background-color: var(--orange);
		}
		
		&.s4::before {
			background-color: var(--red);
		}
	}
}

.theme-claims {
	
	i.status {
		
		&.s0::before {
			background-color: var(--red);
		}
		
		&.s2::before {
			background-color: var(--orange);
		}
		
		&.s3::before {
			background-color: var(--rose);
		}
		
		&.s4::before {
			background-color: var(--red);
		}
	}
	
	.status-lib {
		
		&.s0 {
			color: var(--red);
		}
		
		&.s1 {
			color: var(--green);
		}
		
		&.s2 {
			color: var(--orange);
		}
		
		&.s3 {
			color: var(--rose);
		}
				
		&.s4 {
			color: var(--red);
		}
	}
	
	.input-search {
		background-color: #fbfbfb;
	}
}

@media all and (min-width: $bp-m) {
	.section {
	
		.fielset-layout {
			padding: 2rem 6rem;
		}
	}
}