/**
 * ==================== @ BASE STYLES
 */

// CSS Reset
html {
  box-sizing: border-box;
  font-size: $default-font-size;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
	background-color: $grey-lite;
  color: $black-txt;
  font: normal $fw-regular 1em $default-font-stack;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

p {
  max-width:100%; // Compatibility IE11
	margin-top: .5rem;
	margin-bottom: 1rem;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .5rem;
  margin-bottom: 1.5rem;
}

h1 {
	font-size: em(32);
}

h3 {
	font-size: em(20);
}

h4 {
	font-size: em(16);
}

iframe {
  border: 0 none;
}

img {
	border-radius: 2px;
  height: auto;
  max-width: 100%;
}

input,
textarea,
select {
  outline: none;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-href] {
  cursor: pointer;
}