/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.main {
	margin-top: 160px;
  position:relative;
  z-index:1;
}

.l-wrapper {
  margin: 0 auto;
  //max-width: 90em;
	max-width: 98em;
  position:relative;
	padding: 1rem 2rem;
}

.l-scrollbar {
  position: relative;
  overflow: hidden;
}

.l-grid {
  margin: 0 -10px;

  .grid__item {
    padding: 10px;
		
    &.m--same-height {
      height:100%;
    }
  }
}

/* --- EMBED --- */
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;
	width: 100%;

 	// Ratio modifier
  &.m--r44 {
		padding-bottom: 44.4%;
	}
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.is-semantic {
  @extend %is-semantic;
}

.sv-g {
  font: inherit;
}

.dialog-off-canvas-main-canvas{
  overflow:hidden;
}

:root{
  --grey: #6F6F6F;
  --blue: #0080FF;
  --green: #00B000;
  --orange: #EB8941;
  --red: #D90000;
  --rose: #F0F;
  --yellowgreen: #9acd32;
  --liteseagreen: #20b2aa;
}

@media all and (min-width: 1152px) {
	
	.l-wrapper {
		padding: 1rem 4rem;
		max-width: 108em;
	}
}