/**
 * ==================== @ ICONS
 */

.svg-icon {
  display: inline-block;
  fill: currentColor;
	margin-left: .2rem;
  height: 1em;
	width: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em; /* https://fvsch.com/code/svg-icons/how-to/#section-styling */
	
	&.pdf {
		color: red;
	}
	
	&.zip {
		color: #f7be0b;
	}
	
	&.doc {
		color: #285192;
	}
	
	&.xls {
		color: #1e6e42;
	}	
}
