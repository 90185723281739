/*
 * ==================== @ User login page
 */
 
.user-login {
	
	.user-login__visual {
		height: 100vh;
		position: relative;
		
		h1 {
			color: $white;
			position: absolute;
			font-size: em(50);
			font-family: $alt-font-stack;
			font-weight: $fw-bold;
			text-transform: uppercase;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
		
		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}		
	}
	
	.user-login__connect {
		background-color: $black;
		color: $white;
	}
	
	.user-login__logos {
		text-align: center;
		padding: 2rem 0;
		
		.websites-logos img {
			max-height: 50px;
			max-width: 135px;			
		}
	}
	
	.lost-password {
		padding: 0;
		text-align: right;
		
		a {
			font-size: em(12);
			text-decoration: underline;
		}
	}
	
	.user-login__form {
		margin: 18vh auto 0 auto;
		max-width: 300px;
		
		.m--actions-full {
			padding-top: 2rem;
			width: 100%;
			margin: 0;
		}
		
		.btn {
			width: 100%;
		}
	}
}