/**
 * ==================== @ HEADER
 */

.header {
	background-color: $black;
	color: $white;
	height: 46px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	padding-left: 1rem;
	padding-right: 1rem;
	z-index: 10;
}

.header__logos {	
	text-align: center;
}

.websites-logos {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;

	li {
		margin: 0 .75rem;
	}
	
	img {
		max-height: 34px;
		max-width: 90px;
	}
}

.header__menu-top {
	
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
	}
	
	li {
		font-size: em(12);
		font-weight: $fw-medium;
		margin: 0 .75rem;
	}
	
}

/**
 * ==================== @ NAV
 */

.nav {
	background-color: $white;
	box-shadow: 0 5px 52px 0 rgba(0, 96, 156, 0.1);
	position: fixed;
	left: 0;
	right: 0;
	top: 46px;
	height: 100px;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	z-index: 8;
	
	.nav__title {
		color: $blue;
		font-size: em(24);
		font-weight: $fw-bold;
		padding-left: 2rem;
		padding-right: 4rem;
		text-transform: uppercase;
	}
	
	.nav__menu {
		
	}
	
	.navigationMenu {
		
		li {
			display: inline-block;
			
			a {
				display: block;
				margin: .3rem .5rem;				
			}
			
			&.active a{
				color: $blue;
			}
			
			&:first-child,
			.account {
				display: none;
			}
		}
		
		a {
			font-size: em(14);
			font-weight: $fw-semibold;
			
			&:hover {
				color: $blue;
			}
		}
	}
}

