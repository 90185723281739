/*
 * ==================== @ MAGNIFIC POPUP THEME
 */


.mfp-bg {
	background: $black;
	opacity: .96;	
}

.mfp-iframe-holder .mfp-content {
	max-width: 960px;
}

/* --- POPIN TRANSAT EN IMAGES / PHOTOS GALLERY --- */

.mfp-pictures, .mfp-gallery {

	.mfp-close {
		background: #fff;
		color: $black;
		width: 44px;
		text-align: center;
		padding-right: 0;
	}
	
	.mfp-figure {
		max-width: 100%;
		width: max-content;
		margin: 0 auto;
		position: relative;
		
		&::after {
			content: none;
		}
		
		.mfp-title{
			text-align: center;
		}
		
		.mfp-close {
			
		}
		
		.mfp-img {
			padding-top: 0;
		}
	}
	
	.mfp-arrow  {
		background: $grey-lite;
		width: 56px;
		height: 56px;
		margin-top: -28px;
		opacity: 1;
		transition: left 200ms, right 200ms;
		
		&::before, &::after {
			content: none;
		}
		
		&.mfp-arrow-left {
			//@include background-svg($svg-chevron-left, $blue-ice);
			left: 1%;
			
			&:hover {
				left: .8%;
			}
		}
		
		&.mfp-arrow-right {
			//@include background-svg($svg-chevron-right, $blue-ice);
			right: 1%;
			
			&:hover {
				right: .8%;
			}
		}
	}
	
	.mfp-bottom-bar {
		background: rgba($white, .3);
		padding: .5em;
		position: relative;
		max-width: 100%;
		
		.mfp-counter {
			padding: 8px;
		}
	}
}

@media all and (max-width: 440px) {

	.mfp-wrap {
		
		.mfp-iframe-holder {
			padding-top: 6px !important;
			padding-bottom: 6px !important;
			
			.mfp-content {
				height: 100% !important;
			}
		}
	}
}