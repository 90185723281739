/**
 * ==================== @ SASS PLACEHOLDERS
 */

%clearfix {
  &::before, &::after { content: " "; display: table; }
  &::after { clear: both; }
}

%ghost-valign {
  white-space: nowrap;

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

%ghost-valign-inner {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
