.ms-options-wrap {
    position: relative;
}

.ms-options-wrap,
.ms-options-wrap * {
    box-sizing: border-box;
}

.ms-options-wrap > button:focus,
.ms-options-wrap > button {
    position: relative;
    width: 120px;
		max-width: 120px;
    text-align: left;
    border: 1px solid #aaa;
    background-color: #fff;
    padding: 5px 20px 5px 5px;
    margin-top: 1px;
    font-size: 13px;
    color: #aaa;
    outline-offset: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ms-options-wrap > button[disabled] {
    background-color: #e5e9ed;
    color: #808080;
    opacity: 0.6;
}

.ms-options-wrap > button:after {
    content: ' ';
    height: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    border: 6px solid rgba(0, 0, 0, 0);
    border-top-color: #999;
    margin-top: -3px;
}

.ms-options-wrap.ms-has-selections > button {
    color: #333;
}

.ms-options-wrap > .ms-options {
    position: absolute;
    left: 0;
    //width: 100%;
		width: 200px;
    margin-top: 1px;
    margin-bottom: 20px;
    background: white;
    z-index: 2000;
    border: 1px solid #aaa;
    overflow: auto;
    visibility: hidden;
		
		label {			
			cursor: pointer;
		}
}

.ms-options-wrap.ms-active > .ms-options {
    visibility: visible
}

.ms-options-wrap > .ms-options > .ms-search input {
    width: 100%;
    padding: 4px 5px;
    border: none;
    border-bottom: 1px groove;
    outline: none;
}

.ms-options-wrap > .ms-options > .no-result-message {
    padding: 4px 5px;
    display: none;
}

.ms-options-wrap > .ms-options .ms-selectall {
    display: inline-block;
    font-size: .9em;
    text-transform: lowercase;
    text-decoration: none;
}
.ms-options-wrap > .ms-options .ms-selectall:hover {
    text-decoration: underline;
}

.ms-options-wrap > .ms-options > .ms-selectall.global {
    margin: 4px 5px;
}

.ms-options-wrap > .ms-options > ul,
.ms-options-wrap > .ms-options > ul > li.optgroup ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ms-options-wrap > .ms-options > ul li.ms-hidden {
    display: none;
}

.ms-options-wrap > .ms-options > ul > li.optgroup {
    padding: 5px;
}
.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup {
    border-top: 1px solid #aaa;
}

.ms-options-wrap > .ms-options > ul > li.optgroup .label {
    display: block;
    padding: 5px 0 0 0;
    font-weight: bold;
}

.ms-options-wrap > .ms-options > ul label {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 4px 4px 4px 20px;
    margin: 1px 0;
    border: 1px dotted transparent;
		font-size: em(14);
		font-weight: normal;
}
.ms-options-wrap > .ms-options.checkbox-autofit > ul label,
.ms-options-wrap > .ms-options.hide-checkbox > ul label {
    padding: 4px;
}

.ms-options-wrap > .ms-options > ul label.focused,
.ms-options-wrap > .ms-options > ul label:hover {
    background-color: #efefef;
    border-color: #999;
}

.ms-options-wrap > .ms-options > ul li.selected label {
    background-color: #efefef;
    border-color: transparent;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] {
    margin: 0 5px 0 0;
    position: absolute;
    left: 3px;
    top: 4px;
		width: auto !important;
}

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}
