/*
 * ==================== @ PAGES - Head and body
 */


/* Flash message
----------------------------------*/
.success {
	background-color: #50b550;
	color: #FFF;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	padding: 6px 0;
	margin-left: -150px;
	position: absolute;
	text-align: center;
	top: -22px;
	left: 50%;
	width: 300px;
	
	&.failed {
		background-color: #F60;
		color: #000;
	}
}

/* Head page
----------------------------------*/
.page-header {
	
	.page-header__title {
		color: $blue;
		font-size: em(29);		
	}
	
	.page-header__subnav {
		border-bottom: 1px solid $grey-border;
		padding-bottom: 0;
		
		.navigation {
			display: block;			
			
			li {
				color: $blue;
				display: inline-block;
				font-size: em(14);
				font-weight: $fw-medium;
				margin-right: 2rem;
				
				&:last-child {
					margin-right: 0;
				}
			
				a {
					display: block;
					padding-bottom: 17px;
				}
				
				&.active a, &:hover a {					
					border-bottom: 1px solid $blue-ice;		
				}
			}
		}		
	}
	
	.page-header__direct-acces {
		float: right;
		
		ul {
			margin-top: -4rem;
			position: relative;
		}
		
		li {
			display: inline-block;
			margin-left: 1rem;
		}
	}	
}

.nav_dealer .page-header {
	
	.page-header__subnav {
		
		/* If not Admin, hide some sub menus
		----------------------------------*/
		.navigation {
			li:nth-of-type(2) {
				display: none;
			}
			li:nth-of-type(4) {
				display: none;
			}			
		}
	}	
}

/* Lost & reset password
----------------------------------*/

.reset__wrapper {
	background-color: #fff;
	box-shadow: 0 5px 36px rgba(158,161,166,.2);
	max-width: 48em;
	margin: 4rem auto;
	padding: 1rem;
	
	fieldset {
		max-width: 50%;
	}
	
	ul li {
		margin: 1rem 0;
	}
}


.section {	
	padding: 1rem 0;
	
	.section__title {
		font-size: em(20);
		color: $blue;
		text-align: center;
	}
}