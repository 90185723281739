/**
 * ==================== @ BUTTONS
 */
 
.btn-wrapper {
	
	&.m--center {
		text-align: center;
	}
	
	&.m--padding {
		padding: 1rem 0;
	}
}

.btn {
	background-color: $blue;
	border-radius: 3px;
	color: $white;
	display: inline-block;
	font-size: em(15, 16);
	font-weight: $fw-medium;
	padding: .75rem 2rem;
	text-align: center;
	white-space: nowrap;
	transition: background-color 250ms;
	
	&.m--blueice {
		background-color: $blue-ice;
	}
	
	&.m--white {
		background-color: $white;
		color: $blue;
		font-weight: $fw-medium;
		
		&:hover {
			background-color: rgba($white, .9);
		}
	}
	
	&.m--medium {
		font-size: em(13, 16);
		padding: .65rem 2rem;
		
		&.m--supp .svg-icon {
			font-size: 15px;
			margin-left: -.75rem;
			margin-right: .75rem;
		}
	}
	
	&.m--mini {
		font-size: em(11, 16);
		padding: .25rem 1rem;
	}
	
	&:hover {
		background-color: rgba($blue, .8);
	}
	
	.svg-icon {
		font-size: em(12);
		vertical-align: -1px;
	}
	
	&.m--dropbox {
		.svg-icon {
			font-size: em(18);
			vertical-align: -3px;
		}
	}
	
	&.m--back {
		.svg-icon {
			transform: rotate(180deg);
			vertical-align: 0;
		}
	}
	
	&.m--download {
		.svg-icon {
			transform: rotate(90deg);
		}
	}
	
	&.m--plus {
		padding: .35rem .75rem;
		
		.svg-icon {
			font-size: em(16);
		}
	}
	
	&.m--add, &.m--rem {
		background-color: $grey-lite;
		border: 1px solid $grey-caption;
		border-radius: 2px;
		padding: 0;
		line-height: 5px;
		text-align: center;
		height: 32px;
		width: 32px;
		
		&::before {
			font-size: em(30);
		}
		
		&:hover {
			background-color: $grey-caption;
		}
	}
	
	&.m--add {
		
		&::before {
			content: '+';
			color: $green;
		}
	}
	
	&.m--rem {
		
		&::before {
			content: '-';
			color: $red;
		}
	}
	
	td & {
		font-size: 13px;		
	}
}

.btn-link-garbage {
	font-size: em(16);
		
	.svg-icon {
		color: $red;
		vertical-align: -1px;
	}
	
	&:hover {
		text-decoration: underline;
	}
}

.btn-mini {
	color: $grey;
	display: block;
	height: 16px;
	padding: 0 .2rem;
	width: 32px;
	
	&.m--edit {
		@include background-svg($svg-edit, $grey);
	}
	
	&.m--view {
		@include background-svg($svg-view, $grey);
	}
	
	&.m--supp {
		@include background-svg($svg-supp, $grey);
	}	
	
	&.m--print {
		@include background-svg($svg-print, $grey);
	}
	
	&.m--edit:hover {
		@include background-svg($svg-edit, $green);
	}
	
	&.m--view:hover {
		@include background-svg($svg-view, $green);
	}
	
	&.m--supp:hover {
		@include background-svg($svg-supp, $red);
	}
	
	&.m--print:hover {
		@include background-svg($svg-print, $green);
	}
}
