/**
 * ==================== @ PAGINATION
 */

.paginator {
	padding-bottom: 1rem;
	text-align: right;
	
	span, a {
		display: inline-block;
		font-size: em(13);
		font-weight: $fw-medium;
		line-height: 24px;
		height: 24px;	
		width: 24px;
		text-align: center;
	
		&.active, &:not(.disabled):hover {
			background-color: $white;
			color: $blue-ice;
			font-weight: $fw-bold;
		}
		
		&.disabled {
			display: none;
		}
	}
}