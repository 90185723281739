/**
 * ==================== @ WARRANTY / Contracts & Claims
 */

.table-layout {
	
	&.theme-claims {
		
		thead .rowlast {
			
			input, select {
				font-size: em(14);
				max-width: 110px;
				width: 100%;
				padding: 4px;
			}
		}		
	}
}