/*
 * ====== Home connected 
 */

.home {
	
	.l-wrapper {
		max-width: 80em;
		padding: 0 10rem;
	}
	
	/* Hide message success */
	.success, .error {
		display: none;
	}
	
	.main-news {
		
		.grid-cols {
			background-color: $white;
		}
		
		.news-visual {
			max-width: 420px;
			
			img {
				display: block;
			}
		}
		
		.grid-col-text {
			padding: 2rem;
			
			.news-publish {
				color: $blue-ice;
				display: block;
				font-size: em(14);
				font-weight: $fw-medium;
				padding: 1rem 0;
			}
			
			.news-title {
				font-size: em(20);
				font-weight: $fw-medium;
			}
			
			.entry {
				color: rgba($black-txt, .6);
				font-weight: $fw-medium;
			}
		}		
	}
	
	.content-shortcut {
		
		.sv-u-1 {
			margin-bottom: 1rem;			
		}
		
		.grid-col__outer {
			border-radius: 2px;
			height: 100%;
			padding: 3rem 4rem 4rem 4rem;
			position: relative;
			
			.btn {
				position: absolute;
				bottom: 2rem;
			}
		}
		
		.sv-u-1:first-child {
			
			.grid-col__outer {
				background-color: $white;
			}
			
			h3 {
				font-size: em(19);
				font-weight: $fw-semibold;
				line-height: 1.1;
			}
		}
		
		.sv-u-1:last-child {
			
			.grid-col__outer {
				background-color: #EBF3FA;
			}
			
			h3 {
				font-size: em(24);
				font-weight: $fw-semibold;
				line-height: 1.4;
				margin-bottom: 2.5rem;
			}		
		}		
	}
}

@media all and (min-width: $bp-l) {
	
	.home {
		
		.content-shortcut {
			
			.sv-u-1:first-child .grid-col__outer {
				margin-right: 1rem;				
			}
			
			.sv-u-1:last-child .grid-col__outer {
				margin-left: 1rem;
			}			
		}		
	}	
}