/**
 * ==================== @ FORMS STYLES
 */

.form-wrapper {
	position: relative;
	
	.form-message {
		font-size: em(15);
		padding: .5rem;
		
		.error {
			color: var(--yellowgreen);
		}		
	}
	
	&.m--black {
		
		.form-field .errors {
			color: var(--yellowgreen);
		}		
	}
	
	.toggle--view {
		display: none;
		
		&.active {
			display: block;
		}
	}
	
	.sv-g > .toggle--view {
		width: 100%;
	}
}

.form-field {
	padding: .5rem;
	
	&.m--hidden {
		display: none;
	}
	
	&.m--autosize {
		input[type=text], select {
			width: auto;
		}
	}
		
	&.m--right, &.m--right-no-padd {
		text-align: right;
	}
		
	&.m--right {		
		padding-top: 2.2rem;
	}
	
	.note {
		font-size: em(12);
		font-style: italic;
	}
	
	.errors {
		color: $red;
		font-size: em(11);
	}
	
	.form-field__static {
		background-color: $grey-field;
		border-radius: 4px;
		border: none;
		color: var(--liteseagreen);
		display: block;
		font-size: em(15);
		padding: .5rem;	
		min-height: 35px;
	}
	
	&.radio-inline {
		label {
			display: inline-block;
			
			&.form-label {
				display: block;
			}
			
			&:not(.form-label) {
				cursor: pointer;
				font-size: em(15);
				padding: .3rem 1rem .3rem 0;
				white-space: nowrap;
			}
		}		
	}
	
	&.check-inline {
		label {
			cursor: pointer;
			display: inline;
		}
		
		input[type=checkbox] {
			cursor: pointer;
			float: left;
			margin-top: 3px;	
		}				
	}
	
	&.actions {
		padding-bottom: 1rem;
		width: max-content;
		margin: 0 auto;
		
		&.m--right {
			text-align: right;
		}
		
		&.m--center {
			text-align: center;
		}
	}
	
	.sv-u-1 & {
		&.radio-inline {
			
			.form-label {
				display: inline-block;
				width: 50%;
			}			
		}
		
		&.check-inline {
			padding-top: 1rem;
		}
	}
	
	/* Custom fix for select in export SAV
	----------------------------------*/
	&.m--filter-statuses {
		
		label {
			float: left;
			margin-right: 1rem;
			margin-top: 22px;
		}
		
		select {
			float: left;
		}
	}
}

.form-label {
	display: inline-block;
	font-size: em(15);
	padding: .3rem 0;
}

label.field--required::after,
.form-field.field--required label::after {
	content: ' *';
	color: $grey;
	font-weight: $fw-bold;
}

.form-field-mention {
	background-color: #ebf9fd;
	border-radius: 2px;
	font-size: em(15);
	line-height: 1.4;
	margin: .5rem;
	padding: 1rem;
	
	&.m--alert {
		background-color: #fcebeb;
	}
}

[role=contentinfo]:not(footer) {
  display: none;
}


form {
	
	input, select, textarea {
		background-color: $grey-field;
		border-radius: 4px;
		border: none;
		color: $grey;
		font-size: em(15);
		padding: .5rem;
		max-width: 100%;
		width: 100%;
		
		&.m--autosize {
			width: auto;
		}
	}
	
	input[type=radio] {
		width: auto;
		margin-right: .5rem;		
	}
	
	input[type=checkbox] {
		width: auto;
		margin-right: .5rem;
	}
	
	textarea {
		height: 66px;
		line-height: 1.1;
		
		&.bigger {
			height: 180px;
			line-height: 1.5;
		}
		
		&.wide {
			height: 240px;
			line-height: 1.5;
		}
	}
	
	select {
		cursor: pointer;
	}
	
	input[type=submit], button[type=submit] {
		background-color: $blue;
		border-radius: 3px;
		color: $white;
		display: inline-block;
		font-size: em(15);
		font-weight: $fw-semibold;
		padding: .5rem 2rem .5rem 2rem;
		text-align: center;
		white-space: nowrap;
		transition: background-color 250ms;
	
		&:hover {
			background-color: rgba($blue, .8);
		}
	}
	
	input[disabled], 
	select[disabled], 
	input[readonly], 
	textarea[readonly] {
		color: var(--liteseagreen);
	}
	
	button[type=submit] {
		padding: .75rem 2rem;
	}
	
	.checkvalid {
		min-width: 52px;
	}
	
	.checkvalid:not(:placeholder-shown) {
	
		&:valid {
			color: var(--green);
		}
		/*&:not(:focus):invalid {
			color: $red;
		}*/
	}
	
	.errors {
		color: var(--red);
		font-size: em(10);
	}
	
	input.error_holder {
		height: 2px;
	}
}

/* Flash messages
----------------------------------*/

.flash {
	
	&.error-warranty-check-xdc {
		color: var(--orange);
		position: absolute;
		left: 10%;
		top: 70%;
	}
}