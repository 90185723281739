/**
 * Fonts
*/

@mixin fontface($name, $path, $weight: null, $style: null, $exts:woff2 woff) {
  $src: null;
  @each $ext in $exts {
    $src: append($src, url(quote($path + "." + $ext+"?v=1")), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@include fontface('DINPro','/fonts/DINPro', $fw-regular, normal);
@include fontface('DINPro','/fonts/DINPro-bold', $fw-bold, normal);
